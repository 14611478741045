import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import { OptionInput } from '../../dist/js/bolt';
import '../../dist/css/bolt-css.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "option-input"
    }}>{`Option Input`}</h1>
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface IOptionInputDataset {
  type: 'radio' | 'checkbox'; // tipo do option-input
  checked: boolean; // se está selecionado
  label?: string; // texto botão
  value?: any; // valor a ser recuperado no evento onChange
  color?: 'primary' | 'secondary' | 'error'; // estilo de cor
  clean?: boolean; // se a cor será aplicada apenas no elemento
}

export interface IOptionInputRef extends HTMLElement {
  dataset: Partial<Record<keyof IOptionInputDataset, string>>;
}

export interface IOptionInputProps {
  ref: IOptionInputRef;
}

export interface IOptionInputConstructor {
  new (props: IOptionInputProps): IOptionInput;
}

export interface IOptionInputChange {
  value: any;
  checked: boolean;
}

export interface IOptionInput {
  readonly destroy: () => void; // método para desmontar o elemento

  onChange?: (ev: IOptionInputChange) => void; // evento disparado sempre que há alteração no estado do elemento
}
`}</code></pre>
    <h2 {...{
      "id": "radio-button"
    }}>{`Radio Button`}</h2>
    <br />
    <Playground __position={1} __code={'() => {\n  const [radios, setRadio] = useState(null)\n  const [checked, setChecked] = useState(\'\')\n  const radioRef = useRef()\n  const radioRef1 = useRef()\n  const radioRef2 = useRef()\n  //\n  useEffect(() => {\n    if (\n      radioRef.current &&\n      radioRef1.current &&\n      radioRef2.current &&\n      !radios\n    ) {\n      setRadio([\n        new OptionInput({ ref: radioRef.current }),\n        new OptionInput({ ref: radioRef1.current }),\n        new OptionInput({ ref: radioRef2.current }),\n      ])\n    }\n    //\n    if (radios) {\n      radios.forEach(radio => {\n        radio.onChange = selectRadio\n      })\n    }\n    //\n    return () => {\n      if (radios) {\n        radios.forEach(radio => {\n          radio.destroy()\n        })\n      }\n    }\n  }, [radioRef.current, radios, radioRef1.current, radioRef2.current])\n  //\n  const selectRadio = ({ value }) => {\n    console.log(value)\n    setChecked(value)\n  }\n  return (\n    <div className=\"tw-flex\">\n      <div\n        ref={radioRef2}\n        data-type=\"radio\"\n        data-label=\"vue\"\n        data-value=\"vue\"\n        data-checked={checked === \'vue\'}\n      ></div>\n      <div\n        ref={radioRef}\n        data-type=\"radio\"\n        data-label=\"react\"\n        data-value=\"react\"\n        data-checked={checked === \'react\'}\n      ></div>\n      <div\n        ref={radioRef1}\n        data-type=\"radio\"\n        data-label=\"svelt\"\n        data-value=\"svelt\"\n        data-checked={checked === \'svelt\'}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      OptionInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [radios, setRadio] = useState(null);
        const [checked, setChecked] = useState('');
        const radioRef = useRef();
        const radioRef1 = useRef();
        const radioRef2 = useRef(); //

        useEffect(() => {
          if (radioRef.current && radioRef1.current && radioRef2.current && !radios) {
            setRadio([new OptionInput({
              ref: radioRef.current
            }), new OptionInput({
              ref: radioRef1.current
            }), new OptionInput({
              ref: radioRef2.current
            })]);
          } //


          if (radios) {
            radios.forEach(radio => {
              radio.onChange = selectRadio;
            });
          } //


          return () => {
            if (radios) {
              radios.forEach(radio => {
                radio.destroy();
              });
            }
          };
        }, [radioRef.current, radios, radioRef1.current, radioRef2.current]); //

        const selectRadio = ({
          value
        }) => {
          console.log(value);
          setChecked(value);
        };

        return <div className="tw-flex">
        <div ref={radioRef2} data-type="radio" data-label="vue" data-value="vue" data-checked={checked === 'vue'}></div>
        <div ref={radioRef} data-type="radio" data-label="react" data-value="react" data-checked={checked === 'react'}></div>
        <div ref={radioRef1} data-type="radio" data-label="svelt" data-value="svelt" data-checked={checked === 'svelt'}></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h2>
    <br />
    <Playground __position={4} __code={'() => {\n  const [checkboxes, setCheckboxes] = useState(null)\n  const [checked, setChecked] = useState({})\n  const refs = Array(10)\n    .fill(null)\n    .map(() => useRef())\n  //\n  useEffect(() => {\n    if (!checkboxes) {\n      let test = true\n      //\n      refs.some(ref => {\n        if (!ref.current) {\n          test = false\n          return true\n        }\n      })\n      //\n      if (test) {\n        setCheckboxes(refs.map(ref => new OptionInput({ ref: ref.current })))\n      }\n    } else {\n      checkboxes.forEach(check => {\n        check.onChange = onChangeHandler\n      })\n    }\n    //\n    return () => {\n      if (checkboxes) {\n        checkboxes.forEach(check => {\n          check.destroy()\n        })\n      }\n    }\n  }, [...refs, checkboxes])\n  //\n  const onChangeHandler = ({ value, checked }) => {\n    setChecked(old => ({ ...old, [value]: checked }))\n  }\n  return (\n    <div className=\"tw-flex\">\n      {refs.map((ref, index) => (\n        <div\n          key={index}\n          ref={ref}\n          data-type=\"checkbox\"\n          data-label={index < 9 ? `0${index + 1}` : index + 1}\n          data-value={index}\n          data-checked={checked[index]}\n        ></div>\n      ))}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      OptionInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [checkboxes, setCheckboxes] = useState(null);
        const [checked, setChecked] = useState({});
        const refs = Array(10).fill(null).map(() => useRef()); //

        useEffect(() => {
          if (!checkboxes) {
            let test = true; //

            refs.some(ref => {
              if (!ref.current) {
                test = false;
                return true;
              }
            }); //

            if (test) {
              setCheckboxes(refs.map(ref => new OptionInput({
                ref: ref.current
              })));
            }
          } else {
            checkboxes.forEach(check => {
              check.onChange = onChangeHandler;
            });
          } //


          return () => {
            if (checkboxes) {
              checkboxes.forEach(check => {
                check.destroy();
              });
            }
          };
        }, [...refs, checkboxes]); //

        const onChangeHandler = ({
          value,
          checked
        }) => {
          setChecked(old => ({ ...old,
            [value]: checked
          }));
        };

        return <div className="tw-flex">
        {refs.map((ref, index) => <div key={index} ref={ref} data-type="checkbox" data-label={index < 9 ? `0${index + 1}` : index + 1} data-value={index} data-checked={checked[index]}></div>)}
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <br />
    <Playground __position={7} __code={'() => {\n  const [radios, setRadio] = useState(null)\n  const [checked, setChecked] = useState(\'\')\n  const radioRef = useRef()\n  const radioRef1 = useRef()\n  const radioRef2 = useRef()\n  //\n  useEffect(() => {\n    if (\n      radioRef.current &&\n      radioRef1.current &&\n      radioRef2.current &&\n      !radios\n    ) {\n      setRadio([\n        new OptionInput({ ref: radioRef.current }),\n        new OptionInput({ ref: radioRef1.current }),\n        new OptionInput({ ref: radioRef2.current }),\n      ])\n    }\n    //\n    if (radios) {\n      radios.forEach(radio => {\n        radio.onChange = selectRadio\n      })\n    }\n    //\n    return () => {\n      if (radios) {\n        radios.forEach(radio => {\n          radio.destroy()\n        })\n      }\n    }\n  }, [radioRef.current, radios, radioRef1.current, radioRef2.current])\n  //\n  const selectRadio = ({ value }) => {\n    console.log(value)\n    setChecked(value)\n  }\n  return (\n    <div className=\"tw-flex\">\n      <div\n        ref={radioRef2}\n        data-type=\"radio\"\n        data-label=\"vue\"\n        data-value=\"vue\"\n        data-checked={checked === \'vue\'}\n        data-color=\"primary\"\n      ></div>\n      <div\n        ref={radioRef}\n        data-type=\"radio\"\n        data-label=\"react\"\n        data-value=\"react\"\n        data-checked={checked === \'react\'}\n        data-color=\"secondary\"\n      ></div>\n      <div\n        ref={radioRef1}\n        data-type=\"radio\"\n        data-label=\"svelt\"\n        data-value=\"svelt\"\n        data-checked={checked === \'svelt\'}\n        data-color=\"error\"\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      OptionInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [radios, setRadio] = useState(null);
        const [checked, setChecked] = useState('');
        const radioRef = useRef();
        const radioRef1 = useRef();
        const radioRef2 = useRef(); //

        useEffect(() => {
          if (radioRef.current && radioRef1.current && radioRef2.current && !radios) {
            setRadio([new OptionInput({
              ref: radioRef.current
            }), new OptionInput({
              ref: radioRef1.current
            }), new OptionInput({
              ref: radioRef2.current
            })]);
          } //


          if (radios) {
            radios.forEach(radio => {
              radio.onChange = selectRadio;
            });
          } //


          return () => {
            if (radios) {
              radios.forEach(radio => {
                radio.destroy();
              });
            }
          };
        }, [radioRef.current, radios, radioRef1.current, radioRef2.current]); //

        const selectRadio = ({
          value
        }) => {
          console.log(value);
          setChecked(value);
        };

        return <div className="tw-flex">
        <div ref={radioRef2} data-type="radio" data-label="vue" data-value="vue" data-checked={checked === 'vue'} data-color="primary"></div>
        <div ref={radioRef} data-type="radio" data-label="react" data-value="react" data-checked={checked === 'react'} data-color="secondary"></div>
        <div ref={radioRef1} data-type="radio" data-label="svelt" data-value="svelt" data-checked={checked === 'svelt'} data-color="error"></div>
      </div>;
      }}
    </Playground>
    <br />
    <br />
    <h2 {...{
      "id": "colors-clean"
    }}>{`Colors Clean`}</h2>
    <br />
    <Playground __position={10} __code={'() => {\n  const [radios, setRadio] = useState(null)\n  const [checked, setChecked] = useState(\'\')\n  const radioRef = useRef()\n  const radioRef1 = useRef()\n  const radioRef2 = useRef()\n  //\n  useEffect(() => {\n    if (\n      radioRef.current &&\n      radioRef1.current &&\n      radioRef2.current &&\n      !radios\n    ) {\n      setRadio([\n        new OptionInput({ ref: radioRef.current }),\n        new OptionInput({ ref: radioRef1.current }),\n        new OptionInput({ ref: radioRef2.current }),\n      ])\n    }\n    //\n    if (radios) {\n      radios.forEach(radio => {\n        radio.onChange = selectRadio\n      })\n    }\n    //\n    return () => {\n      if (radios) {\n        radios.forEach(radio => {\n          radio.destroy()\n        })\n      }\n    }\n  }, [radioRef.current, radios, radioRef1.current, radioRef2.current])\n  //\n  const selectRadio = ({ value }) => {\n    console.log(value)\n    setChecked(value)\n  }\n  return (\n    <div className=\"tw-flex\">\n      <div\n        ref={radioRef2}\n        data-type=\"radio\"\n        data-label=\"vue\"\n        data-value=\"vue\"\n        data-checked={checked === \'vue\'}\n        data-color=\"primary\"\n        data-clean={true}\n      ></div>\n      <div\n        ref={radioRef}\n        data-type=\"radio\"\n        data-label=\"react\"\n        data-value=\"react\"\n        data-checked={checked === \'react\'}\n        data-color=\"secondary\"\n        data-clean={true}\n      ></div>\n      <div\n        ref={radioRef1}\n        data-type=\"radio\"\n        data-label=\"svelt\"\n        data-value=\"svelt\"\n        data-checked={checked === \'svelt\'}\n        data-color=\"error\"\n        data-clean={true}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      OptionInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [radios, setRadio] = useState(null);
        const [checked, setChecked] = useState('');
        const radioRef = useRef();
        const radioRef1 = useRef();
        const radioRef2 = useRef(); //

        useEffect(() => {
          if (radioRef.current && radioRef1.current && radioRef2.current && !radios) {
            setRadio([new OptionInput({
              ref: radioRef.current
            }), new OptionInput({
              ref: radioRef1.current
            }), new OptionInput({
              ref: radioRef2.current
            })]);
          } //


          if (radios) {
            radios.forEach(radio => {
              radio.onChange = selectRadio;
            });
          } //


          return () => {
            if (radios) {
              radios.forEach(radio => {
                radio.destroy();
              });
            }
          };
        }, [radioRef.current, radios, radioRef1.current, radioRef2.current]); //

        const selectRadio = ({
          value
        }) => {
          console.log(value);
          setChecked(value);
        };

        return <div className="tw-flex">
        <div ref={radioRef2} data-type="radio" data-label="vue" data-value="vue" data-checked={checked === 'vue'} data-color="primary" data-clean={true}></div>
        <div ref={radioRef} data-type="radio" data-label="react" data-value="react" data-checked={checked === 'react'} data-color="secondary" data-clean={true}></div>
        <div ref={radioRef1} data-type="radio" data-label="svelt" data-value="svelt" data-checked={checked === 'svelt'} data-color="error" data-clean={true}></div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      